// PREVIEW DISABLED UNTILL PAGE DETAIL TEMPLATE COMPLETE

import React, { useState, useEffect } from 'react'
import { createClient } from 'contentful'
import { searchToMap, mapDetailTables, respondTo } from '../utils'

import Layout from '../layouts'
import styled from 'styled-components'
import { transparentize } from 'polished'
import GatsbyLink from 'gatsby-link'
import '../css/style.css'

import DefinitionsTable from '../components/DefinitionsTable'
import PageSection from '../components/PageSection'
import Tabs from '../components/Tabs'
import { Wrapper } from '../styledComponents'

const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.blue};
  padding: 0 0 1rem 1rem;

  ${respondTo.small`
      padding: 0 0 1rem 2rem;
    `}
`

const Crumb = styled(GatsbyLink)`
  font-size: 1rem;
  padding: 0 1rem;

  ${respondTo.small`
      font-size: 0.7rem;
      padding: 0 2rem;
    `}
`

const Error = styled.pre`
  font-size: 1.25rem;
  color: darkred;
  display: block;
  padding: 1rem;
  font-weight: bold;
  background: ${transparentize(0.8, 'darkred')};
`

/**
 * This page doesn't pull it's data pre-build
 * Instead it uses a url query to pull the correct data from the
 * Contentful preview API. This allows us to generate previews
 * for the editors in the Contentful web app.
 */
const Preview = ({ location }) => {
  const [data, setData] = useState(null)
  const [tables, setTables] = useState(null)
  const [id, setId] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    //TODO use searchToMap to get value of id parameter in query
    let search = searchToMap(location.search)
    if (!search.id)
      return setError('Kan ID parameter niet vinden. Check of de URL klopt.')
    setId(search['id'])
  }, [])

  useEffect(() => {
    if (id && !error) {
      const client = createClient({
        space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
        host: 'preview.contentful.com',
        accessToken: process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN,
      })
      client
        .getEntry(id)
        .then(res =>
          setData(
            Object.keys(res.fields).reduce((acc, cv) => {
              let out = { ...acc }
              if (!res.fields[cv].fields) out[cv] = res.fields[cv]
              else out[cv] = res.fields[cv].fields
              return out
            }, {})
          )
        )
        .catch(() =>
          setError(
            'Kon de entry niet ophalen. ID is incorrect of er is geen verbinding.'
          )
        )
    }
  }, [error, id])

  useEffect(() => {
    if (data) {
      delete data.materiaal.afbeelding
      setTables(mapDetailTables(data, 'preview'))
    }
  }, [data])

  return (
    <Layout>
      <Wrapper>
        {error && <Error>{error}</Error>}
        {data && tables && !error && (
          <>
            <Crumb to="/catalogusAZ"> &larr; Terug naar index</Crumb>
            <PageTitle>{`${data.titel} — PREVIEW`}</PageTitle>
            <Tabs
              buttons={[
                'Aanvraaginformatie',
                'Materiaal afname/opslag',
                'Interpretatie',
                'Testeigenschappen',
              ]}
            >
              <PageSection
                sectionHeading={tables.algemeen.sectionHeading}
                noPadding
                flex={2}
                color="lightBeige"
              >
                <DefinitionsTable tableData={tables.algemeen.firstColumn} />
                <DefinitionsTable tableData={tables.algemeen.secondColumn} />
              </PageSection>
              <PageSection
                sectionHeading={tables.verzenden.sectionHeading}
                flex={2}
                noPadding
              >
                <DefinitionsTable tableData={tables.verzenden.firstColumn} />
                <DefinitionsTable tableData={tables.verzenden.secondColumn} />
              </PageSection>
              <PageSection
                sectionHeading={tables.interpretatie.sectionHeading}
                flex
                noPadding
                color="lightBeige"
              >
                <DefinitionsTable
                  tableData={tables.interpretatie.firstColumn}
                />
              </PageSection>
              <PageSection
                sectionHeading={tables.behouden.sectionHeading}
                flex={2}
                noPadding
              >
                <DefinitionsTable tableData={tables.behouden.firstColumn} />
                <DefinitionsTable tableData={tables.behouden.secondColumn} />
              </PageSection>
            </Tabs>
          </>
        )}
      </Wrapper>
    </Layout>
  )
}

export default Preview
