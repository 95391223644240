import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PageSection from './PageSection'
import { BrowserView, MobileView } from 'react-device-detect'

const ButtonWrapper = styled.nav`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
`

const TabButton = styled.button`
  flex: 50%;
  height: 50px;
  color: ${({ theme }) => theme.gray[600]};
  font-size: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.beige};

  ${({ active, theme }) =>
    active && `
    color: ${({ theme }) => theme.magenta};
    border-bottom: 1px solid ${theme.magenta};
    background: #fff !important;
    z-index: ${theme.layers[0] + 1};
  `}

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.magenta};
    border-bottom: 1px solid ${({ theme }) => theme.magenta};
    outline: none;
    background: ${({ theme }) => theme.gray['200']};
    z-index: ${({ theme }) => theme.layers[0] + 2};
  }
`
const TabContent = styled.div`
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: translateY(${({ active }) => (active ? '0' : '20px')});

  will-change: opacity, transform;
  transition: ${({ active }) =>
    active
      ? `opacity 200ms ease-in, transform 200ms ease-in`
      : `opacity 100ms ease-out, transform 100ms ease-out`};
`

/**
 *
 * @param {string[]} buttons array of tab button titles
 */
const Tabs = ({ buttons, children }) => {
  const [currActive, setCurrActive] = useState(0)
  const [active, setActive] = useState(true)
  const tabContent = useRef()

  const switchTab = next => {
    if (next === currActive) return
    setActive(false)
    window.setTimeout(() => {
      setCurrActive(next)
      setActive(true)
    }, 100)
  }

  return (
    <>
      <MobileView renderWithFragment>
        <ButtonWrapper>
          {buttons.map((button, idx) => (
            <TabButton
              onClick={() => switchTab(idx)}
              active={currActive === idx}
              key={`tab__button__${idx}`}
            >
              {button}
            </TabButton>
          ))}
        </ButtonWrapper>
        <PageSection ref={tabContent}>
          <TabContent active={active}>
            {children[currActive].props.children}
          </TabContent>
        </PageSection>
      </MobileView>

      <BrowserView renderWithFragment>{children}</BrowserView>
    </>
  )
}

export default Tabs
