import React, { useState } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { respondTo, parseHtmlLink } from '../utils'

const Table = styled.table`
  display: block;
  flex-grow: 1;
  & + & {
   border-top: solid 2px #ffffff;
  }

  ${respondTo.small`
    max-width: calc(100% - 16px);
    & + & {
      margin-left: 32px;
      border-top: none;
    }
  `}
`

const Def = styled.td`
  flex: ${({ wide }) => (wide ? '0 0 100%' : '0 0 auto')};
  display: ${({ wide }) => (wide ? 'block' : 'inline-block')};
  margin-top: ${({ wide }) => (wide ? '5px' : 0)};
  position: relative;
  line-height: 1.2;
  :focus {
    outline: none;
  }
  /* TABLE */
  > table {

    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    display: block;
    overflow-x: scroll;
    ${respondTo.xsmall`
      display: inline-block;
    `}

    &>thead,
    &>tbody>tr:first-of-type {
      color: ${({ theme }) => theme.magenta};
    }
    & td,
    & th {
      padding: 0.5rem;
      vertical-align: top;
    }
    & tr {
      background-color: white;
      &:nth-child(odd) {
        background-color: ${({ theme }) => theme.lightblue};
      }
    }
    &>thead+tbody {
      & tr {
        color: black !important;
        background-color: ${({ theme }) => theme.lightblue};
      &:nth-child(odd) {
        background-color: white;
      }
    }
    }
  }

  > p > a {
    color: ${({ theme }) => theme.blue};
    text-decoration: underline;
  }

  &:first-child {
    font-weight: bold;
    display: inline-block;
    width: 180px;
  }

  &:nth-child(2) {
    max-width: calc(100vw - 2rem);

    ${respondTo.small`
      max-width: 60%;
    `}
  }
`

const ModalButton = styled.button`
  position: absolute;
  font-size: 1rem;
  font-weight: bold;
  width: 1rem;
  line-height: 1;
  left: 100%;
  top: 0;
  color: ${({ theme }) => theme.blue};
  transform-origin: center center;
  transform: translateX(100%) scale(1.5);

  &[aria-active='true'],
  &:focus,
  &:hover {
    outline: 0;
  }
`

const _Row = styled.tr`
  border-bottom: solid 2px #ffffff;
  position: relative;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 0;

  &:last-child {
    border-bottom: none;
  }
`

const Row = ({ label, value, subValues = null }) => {
  const [modalActive, setModalActive] = useState(false)
  value =
    value && value.constructor && value.constructor === Array
      ? { childMarkdownRemark: { html: value.map(s => s.trim()).join(', ') } }
      : value
  return (
    <_Row>
      <Def>{label}:</Def>
      {subValues ? (
        <Def
          onClick={() => setModalActive(!modalActive)}
          style={{ cursor: 'pointer' }}
        >
          {value}
          <ModalButton tabIndex="0" ariaActive={modalActive}>
            {modalActive ? '-' : '+'}
          </ModalButton>
        </Def>
      ) : value && value.childMarkdownRemark ? (
        <Def
          wide
          dangerouslySetInnerHTML={{
            __html: parseHtmlLink(value.childMarkdownRemark.html),
          }}
        />
      ) : (
        <Def>{value}</Def>
      )}

      {subValues && <Modal active={modalActive} data={subValues} />}
    </_Row>
  )
}

const _Modal = styled.div`
  position: absolute;
  background: white;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  opacity: 0;
  will-change: opacity, transform;
  transition: all 100ms ease-out;
  z-index: 1;
  top: 100%;
  display: block;
  width: 100%;
  overflow: hidden;

  ${props =>
    props.active
      ? `
      opacity: 1;
      box-sizing: border-box;
    `
      : `
      display: none;
    `}

  div p:first-child {
    font-weight: bold;
    width: 150px;
  }

  div p {
    display: inline-block;
  }

  div a {
    color: ${({ theme }) => theme.blue};
    text-decoration: underline;
    cursor: pointer;
  }

  div:not(:last-child) {
    padding-bottom: 10px;
  }
`

const Modal = ({ active, data }) => {
  const [imageOpen, setImageOpen] = useState(false)
  return (
    <_Modal active={active}>
      {data.map((obj, index) => {
        const key = Object.keys(obj)[0]
        const value = obj[key]

        //return image if present
        return value && value.fluid ? (
          <div key={index}>
            <p>{key}</p>
            <a onClick={() => setImageOpen(!imageOpen)}>
              {!imageOpen ? 'Toon afbeelding' : 'Verberg afbeelding'}
            </a>
            <Img
              fluid={value.fluid}
              style={{ display: imageOpen ? 'block' : 'none' }}
            />
          </div>
        ) : (
          <div key={index}>
            <p>{key}</p>
            {key === 'Link' ? (
              <p>
                <a target="_blank" rel="noopener" href={value}>
                  {value}
                </a>
              </p>
            ) : (
              <p>{value}</p>
            )}
          </div>
        )
      })}
    </_Modal>
  )
}

const DefinitionTable = ({ tableData = [] }) => {
  return (
    <Table>
      <tbody>
        {tableData.map((row, i) => (
          <Row {...row} key={`row__${i}`} />
        ))}
      </tbody>
    </Table>
  )
}

export default DefinitionTable
